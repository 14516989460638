import { Injectable } from '@angular/core';
import {
  ComboGroup,
  CreateGroupRequest,
  CreateMenuSectionRequest, DisplayedGroup,
  DisplayedProduct,
  DisplayedSize,
  Group,
  MenuSection,
  ModifierGroup,
  OverridePosition,
  OverridePositionRequest, OverridePositionsMap,
  PositionTypeEnum,
  Product,
  Size,
  UpdateMenuSectionRequest,
} from 'app/models/menu.model';
import { DishesService } from 'app/services/dishes.service';
import { Store } from '@ngrx/store';
import { MenuOverrideService } from 'app/services/menu-override.service';
import * as MenuSelectors from 'app/reducers/menu.selectors';
import {
  selectDictionaryGroupsAndOverridePositions,
  selectInfoForOverridePositions,
  selectMenuOrganization,
  selectMenuSections,
} from 'app/reducers/menu.selectors';
import { tap } from 'rxjs/operators';
import * as MenuActions from 'app/reducers/menu.actions';
import {
  setMenusPending,
  setMenuSyncOfPOS,
  setMenuSyncOfSales
} from 'app/reducers/menu.actions';
import { updateObject } from 'app/shared/utils/updateObject';
import { concatLatestFrom } from '@ngrx/effects';
import { MenuState } from 'app/reducers/menu.reducer';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { menuHelper } from 'app/helpers/menuHelper';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    private dishesService: DishesService,
    private store: Store,
    private translate: TranslateService,
  ) {
  }

  public getNewFullMenuData(organizationId: string){
    this.store.dispatch(setMenusPending(true));
    this.dishesService.getNewFullMenuData$(organizationId)
      .pipe(concatLatestFrom(() => this.store.select(MenuSelectors.selectOverridePositions)))
      .subscribe(([menuResponse, overridePositions]) => {
        if (!menuResponse.success){
          return;
        }
        const gecoPosMenu = menuResponse.data;
        const sortedOriginalDictionaryGroups = new Map<string, Group>();
        const dictionaryProducts = new Map<string, DisplayedProduct>();
        const originalDictionaryProducts = new Map<string, Product>();
        const dictionarySizes = new Map<string, DisplayedSize>();
        const originalDictionarySizes = new Map<string, Size>();
        const dictionaryModifierGroups = new Map<string, ModifierGroup>();
        const originalDictionaryModifierGroups = new Map<string, ModifierGroup>();
        const dictionaryComboGroups = new Map<string, ComboGroup>();
        const originalDictionaryComboGroups = new Map<string, ComboGroup>();

        const sortedOriginalGroups = menuHelper.sortGroupByOrderAndId(gecoPosMenu.groups);
        for (let originalGroup of sortedOriginalGroups) {
          originalGroup.groupStructure.productsInGroup = menuHelper.sortProductByOrderAndId(originalGroup.groupStructure.productsInGroup);
          sortedOriginalDictionaryGroups.set(originalGroup.externalId, JSON.parse(JSON.stringify(originalGroup)));
        }

        const sortedDictionaryGroups = MenuOverrideService.overrideMenu(sortedOriginalGroups, overridePositions);

        const oPositions = new OverridePositionsMap(overridePositions);
        for (const product of gecoPosMenu.products) {
          originalDictionaryProducts.set(product.externalId, JSON.parse(JSON.stringify(product)));
          dictionaryProducts.set(product.externalId, MenuOverrideService.overrideProduct(product, oPositions));
        }
        for (const size of gecoPosMenu.sizes) {
          originalDictionarySizes.set(size.externalId, JSON.parse(JSON.stringify(size)));
          dictionarySizes.set(size.externalId, MenuOverrideService.overrideSize(size, overridePositions));
        }
        for (const modifierGroup of gecoPosMenu.modifierGroups) {
          originalDictionaryModifierGroups.set(modifierGroup.externalId, JSON.parse(JSON.stringify(modifierGroup)));
          dictionaryModifierGroups.set(modifierGroup.externalId, MenuOverrideService.overrideModifierGroup(modifierGroup, overridePositions));
        }
        for (const comboGroup of gecoPosMenu.comboGroups) {
          originalDictionaryComboGroups.set(comboGroup.externalId, JSON.parse(JSON.stringify(comboGroup)));
          dictionaryComboGroups.set(comboGroup.externalId, MenuOverrideService.overrideComboGroup(comboGroup, overridePositions));
        }
        const newState = {
          menu: gecoPosMenu,
          dictionaryGroups: sortedDictionaryGroups,
          originalDictionaryGroups: sortedOriginalDictionaryGroups,
          dictionaryProducts: dictionaryProducts,
          originalDictionaryProducts: originalDictionaryProducts,
          dictionarySizes: dictionarySizes,
          originalDictionarySizes: originalDictionarySizes,
          dictionaryModifiersGroups: dictionaryModifierGroups,
          originalDictionaryModifiersGroups: originalDictionaryModifierGroups,
          dictionaryComboGroup: dictionaryComboGroups,
          originalDictionaryComboGroup: originalDictionaryComboGroups,
        } as MenuState;

        this.store.dispatch(MenuActions.initFullMenu(newState));
      });
  }

  public loadOverridePositions(menuSectionId: string){
    this.dishesService.getOverrides$(menuSectionId)
      .subscribe((
        response) =>
      {
        if (!response.success){
          return;
        }
        const newPositions = response.data;
        this.store.dispatch(MenuActions.setOverridePositions(newPositions));
      });
  }

  public async saveOverridePositions(
    currentMenuSectionId: string,
    overridePositionsRequest: OverridePositionRequest[],
    menuSectionIds: string[])
    :Promise<void>
  {
    let { overridePositions, originalDictionaryGroups,
      originalDictionaryProducts, originalDictionarySizes,
      originalDictionaryModifierGroup, originalDictionaryComboGroup }
      = await firstValueFrom(this.store.select(selectInfoForOverridePositions));

    const response = await this.dishesService.saveOverridePositions(overridePositionsRequest, menuSectionIds);
    const responseOverridePositions = response.data ?? [];

    const newPositions = [...overridePositions];
    const responsePositionTypes = new Set<PositionTypeEnum>();

    for (const overridePosition of responseOverridePositions) {
      // TODO: возможно лучше если сервер будет сразу возвращать overrides только для текущей секции меню
      if (overridePosition.menuSectionId !== currentMenuSectionId){
        continue;
      }

      const index = overridePositions.findIndex(op => op.id === overridePosition.id);
      if (index >= 0) {
        newPositions[index] = overridePosition;
      } else {
        newPositions.push(overridePosition);
      }

      responsePositionTypes.add(overridePosition.positionType);
    }

    for (const positionType of responsePositionTypes) {
      if (positionType === PositionTypeEnum.Menu){
        const dictionaryGroups = this.updateMenu(originalDictionaryGroups, newPositions);
        this.store.dispatch(MenuActions.setDictionaryGroupsAndOverridePositions([newPositions, dictionaryGroups]));
      }
      else if (positionType === PositionTypeEnum.Group || positionType === PositionTypeEnum.GroupStructure) {
        const dictionaryGroups = this.updateMenu(originalDictionaryGroups, newPositions);
        this.store.dispatch(MenuActions.setDictionaryGroupsAndOverridePositions([newPositions, dictionaryGroups]));
      }
      else if (positionType === PositionTypeEnum.Product ||
        positionType === PositionTypeEnum.ProductStructure ||
        positionType === PositionTypeEnum.ComboStructure ||
        positionType === PositionTypeEnum.SizeStructure ||
        positionType === PositionTypeEnum.ModifierGroupStructure ||
        positionType === PositionTypeEnum.ModifierProductStructure ||
        positionType === PositionTypeEnum.ComboProductStructure) {
        const dictionaryProducts = this.updateDictionaryProducts(originalDictionaryProducts, new OverridePositionsMap(newPositions));
        this.store.dispatch(MenuActions.setDictionaryProductsAndOverridePositions([newPositions, dictionaryProducts]));
      } else if (positionType === PositionTypeEnum.Size) {
        const sizes = this.updateDictionarySizes(originalDictionarySizes, newPositions);
        this.store.dispatch(MenuActions.setDictionarySizesAndOverridePositions([newPositions, sizes]));
      } else if (positionType === PositionTypeEnum.ModifierGroup) {
        const dictionaryModifiersGroups = this.updateDictionaryModiferGroups(originalDictionaryModifierGroup, newPositions);
        this.store.dispatch(MenuActions.setDictionaryModifierGroupsAndOverridePositions([newPositions, dictionaryModifiersGroups]));
      } else if (positionType === PositionTypeEnum.ComboGroup) {
        const comboGroups = this.updateDictionaryComboGroups(originalDictionaryComboGroup, newPositions);
        this.store.dispatch(MenuActions.setDictionaryComboGroupsAndOverridePositions([newPositions, comboGroups]));
      } else {
        this.store.dispatch(MenuActions.setOverridePositions(newPositions));
      }
    }

  }

  public loadMenuSyncOfSales(organizationId: string){
    return this.dishesService.getMenuSyncsForSaleSystems(organizationId)
      .pipe(tap(menuSyncsOfSale =>
        {
          this.store.dispatch(setMenuSyncOfSales(menuSyncsOfSale))
        }
    ));
  }

  public loadMenuSyncOfPos(organizationId: string){
    return this.dishesService.getMenuSyncForExternalPOS(organizationId)
      .pipe(
        concatLatestFrom( () => this.store.select(selectMenuOrganization)),
        tap(([ menuSyncsOfPos, posOrganization]) =>
          {
            if (posOrganization?.importFromPos){
              this.store.dispatch(setMenuSyncOfPOS(menuSyncsOfPos));
            }
          }
        )
      );
  }

  public async createMenuSection(createMenuSectionRequest: CreateMenuSectionRequest): Promise<IBaseResponse<MenuSection>>{
    const response = await this.dishesService.createMenuSection(createMenuSectionRequest);
    if (!response.success){
      return response;
    }
    const menuSections = await firstValueFrom(this.store.select(selectMenuSections));
    const updatedMenuSections = [...menuSections, response.data];
    this.store.dispatch(MenuActions.setMenuSections(updatedMenuSections))
    return response;
  }

  public async updateMenuSection(updateMenuSectionRequest: UpdateMenuSectionRequest): Promise<IBaseResponse<MenuSection>>{
    const response = await this.dishesService.updateMenuSection(updateMenuSectionRequest);
    if (!response.success){
      return response;
    }
    const menuSections = await firstValueFrom(this.store.select(selectMenuSections));
    const updatedMenuSections = updateObject(menuSections, 'id', updateMenuSectionRequest);
    this.store.dispatch(MenuActions.setMenuSections(updatedMenuSections));
    return response;
  }

  public createGroup(createGroupRequest: CreateGroupRequest){
    return this.dishesService.createGroup(createGroupRequest)
      .pipe(
        concatLatestFrom(() => this.store.select(selectDictionaryGroupsAndOverridePositions)),
        tap(([response, {dictionaryGroups, overridePositions}]) => {
          if (!response.success){
            return;
          }
          const newGroup: DisplayedGroup = {...response.data, hasReOrderingProductInGroup: false, assortmentHasChanged:false, isFiltered: false};
          const newDictionaryGroups = new Map<string, DisplayedGroup>();
          for (const keyValue of dictionaryGroups.entries()) {
            newDictionaryGroups.set(keyValue[0], MenuOverrideService.overrideGroup(keyValue[1], overridePositions));
          }
          newDictionaryGroups.set(newGroup.externalId, newGroup);
          this.store.dispatch(MenuActions.setDictionaryGroups(newDictionaryGroups));
        })
      );
  }

  public getUpdateInformationMessage(changes: any[]) : string {
    let result = '';
    if (changes.length > 0) {
      result = `${this.translate.instant('MENU.CHANGES_FOR_MENUS', {'changes': changes.join(', ')})}`;
    }

    return result;
  }

  getSavedMenuSectionsIds(selectedMenuSections: object): string [] {
    const saveForMenuSections: string [] = [];
    for (const [key, value] of Object.entries(selectedMenuSections)) {
      if (value) {
        saveForMenuSections.push(key);
      }
    }
    return saveForMenuSections;
  }

  private updateMenu(dictionaryGroups: Map<string, Group>, newPositions: OverridePosition[]): Map<string, DisplayedGroup>
  {
    const newDictionaryGroups = MenuOverrideService.overrideMenu([...dictionaryGroups.values()], newPositions)
    return newDictionaryGroups;
  }

  private updateDictionaryGroups(dictionaryGroups: Map<string, Group>, newPositions: OverridePosition[]): Map<string, DisplayedGroup>
  {
    const newDictionaryGroups = new Map<string, DisplayedGroup>();
    for (const keyValue of dictionaryGroups.entries()) {
      const newGroup = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionaryGroups.set(keyValue[0], MenuOverrideService.overrideGroup(newGroup, newPositions));
    }
    return newDictionaryGroups;
  }

  private updateDictionaryProducts(dictionaryProducts: Map<string, Product>, newPositions: OverridePositionsMap): Map<string, DisplayedProduct>
  {
    const newDictionaryProducts = new Map<string, DisplayedProduct>();
    for (const keyValue of dictionaryProducts.entries()) {
      const newProduct = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionaryProducts.set(keyValue[0], MenuOverrideService.overrideProduct(newProduct, newPositions));
    }
    return newDictionaryProducts;
  }

  private updateDictionarySizes(dictionarySizes: Map<string, Size>, newPositions: OverridePosition[]): Map<string, DisplayedSize>
  {
    const newDictionarySizes = new Map<string, DisplayedSize>();
    for (const keyValue of dictionarySizes.entries()) {
      const newSize = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionarySizes.set(keyValue[0], MenuOverrideService.overrideSize(newSize, newPositions));
    }
    return newDictionarySizes;
  }

  private updateDictionaryModifierGroups(dictionaryModifierGroups: Map<string, ModifierGroup>, newPositions: OverridePosition[]): Map<string, ModifierGroup>
  {
    const newDictionaryModifierGroups = new Map<string, ModifierGroup>();
    for (const keyValue of dictionaryModifierGroups.entries()) {
      const newModifierGroup = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionaryModifierGroups.set(keyValue[0], MenuOverrideService.overrideModifierGroup(newModifierGroup, newPositions));
    }
    return newDictionaryModifierGroups;
  }

  private updateDictionaryComboGroups(dictionaryComboGroups: Map<string, ComboGroup>, newPositions: OverridePosition[]): Map<string, ComboGroup>
  {
    const newDictionaryComboGroups = new Map<string, ComboGroup>();
    for (const keyValue of dictionaryComboGroups.entries()) {
      const newComboGroup = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionaryComboGroups.set(keyValue[0], MenuOverrideService.overrideComboGroup(newComboGroup, newPositions));
    }
    return newDictionaryComboGroups;
  }

  private updateDictionaryModiferGroups(dictionaryModifierGroups: Map<string, ModifierGroup>, newPositions: OverridePosition[]): Map<string, ModifierGroup> {
    const newDictionaryModiferGroups = new Map<string, ModifierGroup>();
    for (const keyValue of dictionaryModifierGroups.entries()) {
      const newModiferGroup = JSON.parse(JSON.stringify(keyValue[1]));
      newDictionaryModiferGroups.set(keyValue[0], MenuOverrideService.overrideModifierGroup(newModiferGroup, newPositions));
    }
    return newDictionaryModiferGroups;
  }
}

